*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}

body {
  /* margin: 1rem 2rem; */
  font-family: 'Montserrat', sans-serif;
  /* background-image:url('https://media.istockphoto.com/id/1222028781/photo/white-paper-texture-vintage-background-white-paper-in-extremely-high-resolution.jpg?s=170667a&w=0&k=20&c=4LK3uCOO-HHW3fM-ssrqnVNV-ydbl87QkLc-GybPLC4='); 
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
  background-color:	#DCDCDC;
}

::-webkit-scrollbar {
  width: 0.6rem;
  background-color:#FFFAF0;
}

::-webkit-scrollbar-thumb {
  background-color:gray;
}


